import * as React from "react"
import HeroArticle from "../components/index/heroArticle"
import HeroImage from "../components/index/heroImage"
import Seo from "../components/seo"
import ContactUs from "../components/index/contactUs"
import TheLatestNews from "../components/index/theLatestNews"
import InstagramFeed from "../components/index/instagramFeed"
import EmailSignup from "../components/index/emailSignup"

const IndexPage = () => (
  <div>
    <Seo title="Home" />
    <HeroImage />
    <HeroArticle />
    <ContactUs />
    <TheLatestNews />
    <InstagramFeed />
    <EmailSignup />
  </div>
)

export default IndexPage
